#particles {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;

}


:root {
  --gradient-shadow: linear-gradient(45deg, #ff0066, #66ffff, #ff66ff, #ffff66, #ff0066);
}
@keyframes shine-animation {
  0% {
    box-shadow: 
      0 0 4px 2px rgba(255, 0, 0, 0.2),    /* Red */
      2px 2px 5px 3.5px rgba(255, 127, 0, 0.24),  /* Orange */
      -2px -2px 5px 4px rgba(255, 255, 0, 0.13); /* Yellow */
  }
  25% {
    box-shadow: 
      2px 2px 4px 2px rgba(127, 255, 0, 0.33),  /* Green */
      0 0 5px 3.5px rgba(0, 255, 0, 0.25),    /* Green */
      -2px -5px 10px 4px rgba(0, 255, 127, 0.1); /* Green */
  }
  50% {
    box-shadow: 
      0 0 4px 2px rgba(0, 255, 255, 0.43),  /* Cyan */
      -2px -5px 5px 3.5px rgba(0, 127, 255, 0.5),  /* Blue */
      2px 5px 7px 4px rgba(0, 0, 255, 0.1);   /* Blue */
  }
  75% {
    box-shadow: 
      -2px -2px 4px 2px rgba(127, 0, 255, 0.15),  /* Indigo */
      0 0 2px 3.5px rgba(139, 0, 255, 0.25),   /* Violet */
      2px 5px 7px 4px rgba(255, 0, 255, 0.1);   /* Violet */
  }
  100% {
    box-shadow: 
      0 0 4px 2px rgba(255, 0, 0, 0.15),    /* Red */
      2px 2px 5px 3.5px rgba(255, 127, 0, 0.13),  /* Orange */
      -2px -5px 7px 4px rgba(255, 255, 0, 0.1); /* Yellow */
  }
}




.gradient-buttons {
  /* Keep all existing properties */
  animation: shine-animation 2.7s linear infinite;
}

.ggradient-button {
  position: relative;
  display: inline-block;
  padding: 8px 25px;
  font-size: 16px;
  border: none;
  background: black; /* Initial background */
  color: white; /* Text color */
  text-decoration: none;
  /* overflow: hidden; */
  border-radius: 6px;
  z-index: 1; /* Ensure button text is above pseudo-elements */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Added shadow */
}

.gradient-button {
  position: relative;
  display: inline-block;
  padding: 8px 25px;
  font-size: 16px;
  border: none;
  background: black; /* Initial background */
  color: white; /* Text color */
  text-decoration: none;
  /* overflow: hidden; */
  border-radius: 6px;
  z-index: 1; /* Ensure button text is above pseudo-elements */
}

.gradient-button:hover {
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.15);
  
}

/* Adjustments to the pseudo-elements */
.gradient-button:after, .gradient-button:before {
  border-radius: 8px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* Start fully transparent */
  background: var(--gradient-shadow);
  background-size: 400%;
  transition: opacity .3s linear;
  z-index: -1; /* Ensure they are behind the button text */
}

.gradient-button:hover:after, .gradient-button:hover:before {
  opacity: 0.8; /* Visible on hover, but behind the text */
  animation: rainbow-animation 5s linear infinite;
}

@keyframes rainbow-animation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.video-fixed {
  position: fixed; /* Make it fixed relative to the viewport */
  top: 0; /* Align to the top of the viewport */
  left: 0; /* Align to the left of the viewport */
  width: 100%; /* Optional: make the video take the full viewport width */
  z-index: 1000; /* Ensure it stays on top of other content */
}

/* Additional styling to ensure content doesn't get hidden behind the fixed video */
.body-content {
  padding-top: 56.25%; /* Adjust based on the aspect ratio of your video */
}

.gradient-buttonn {
  background: black; /* Initial black background */
  color: white; /* White text */
  position: relative; /* Required for absolute positioning of pseudo-elements */
  overflow: hidden; /* Ensures pseudo-elements don't overflow the buttonn */
  border-radius: 20px; /* Rounded corners */
  transition: background .3s linear; /* Smooth transition for the background */
  font-size: 16px; /* Set font size */
}

/* Enlarge box shadow on hover for a "lifted" effect */
.gradient-buttonn:hover {
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.15);
}

/* No need for blur filter on pseudo-elements for this effect */
.gradient-buttonn:after {
  content: "";
}

.gradient-buttonn:before, .gradient-buttonn:after {
  border-radius: 20px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* Keep hidden initially */
  transition: opacity .7s linear;
}

/* Apply the rainbow background on hover */
.gradient-buttonn:hover {
  background: linear-gradient(90deg, #ff0000, #ff7f00, #ffff00, #00ff00, #0000ff, #4b0082, #9400d3);
  animation: rainbow-animation 3s linear infinite;
  background-size: 300% 300%;
}

/* Keep text visible on hover by not altering text color */
.gradient-buttonn:hover:after, .gradient-buttonn:hover:before {
  opacity: 0; /* Ensure pseudo-elements do not interfere with text visibility */
}

.header-btn {
  background-color: #fff; /* White background */
  color: #000; /* Black text */
  padding: 8px 16px;
  border: 1px solid #b6b2b2; /* Black border */
  border-radius: 4px; /* Rounded corners */
  text-decoration: none; /* Remove underline from links */
  cursor: pointer; /* Change cursor to pointer on hover */
  font-size: 14px;
  transition: all 0.3s ease-in-out; /* Smooth transition for all properties */
  white-space: nowrap;
}

.header-btn:hover, .header-btn:focus {
  background-color: #f8f8f8; /* Light grey background for hover/focus */
  outline: none; /* Remove focus outline */
}

@keyframes rainbow-animation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.App-header {
  background-color: #000000 !important;
  padding: 0px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.error-message {
  color: #FF0000; /* Red color for errors */
  margin-top: 10px; /* Spacing above the error message */
  font-size: 0.9em; /* Slightly smaller font size */
}

.App-logo {
  height: 100px; /* or your desired size */
  width: auto; /* to maintain aspect ratio */
  margin-right: 0% !important; /* Adjust the right margin to control spacing */
  padding-right: 0% !important;
}

.App-title {
  font-size: 3em !important;
    font-family: var(--framer-font-family);
    font-style: var(--framer-font-style);
    font-weight: var(--framer-font-weight);
    color: var(--framer-text-color);
    letter-spacing: var(--framer-letter-spacing);
    font-size: var(--framer-font-size);
    text-transform: var(--framer-text-transform);
    text-decoration: var(--framer-text-decoration);
    line-height: var(--framer-line-height);
    margin: 0; /* Optional: Adjusts the margin around the title */
  
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Existing styles */

.results-section {
  /* color: #1b1a1a; */
  margin: 50px auto;
}
/* 
.results-section h2 {
  color: #FF0000;
  margin-bottom: 10px;
}

.results-section p {
  text-align: left;
  white-space: pre-wrap; 
} */

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-top: 10px; /* Adjust based on your layout */
}

.copy-button img {
  width: 24px; /* Adjust size as needed */
  height: auto;
}


.btn{
	display: flex;
	margin: 5rem auto;
	border: none;
	border-radius: 3px;     
	outline: none;
	width: 100px;
	height: 40px;
	background-color: #f08989;
	transition: box-shadow 0.5s;
	font-size: 16px;
	font-family:arial;
	color: #fff;
	cursor: pointer;
}

.btn:hover{
	-webkit-box-shadow: inset 100px 0 0 0 #FF0000;
    box-shadow: inset 100px 0 0 0 #FF0000;
}

.progressbar{
	position: relative;
	overflow: hidden;
	width: 350px;
	height: 10px;
	border-radius: 5px;
	background-color: #eee;
}

.progressPercent{
	font-weight: 600;
	font-family:'Franklin Gothic Medium';
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	color: #eee;
	text-shadow: -1px 0 #555, 0 1px #555, 1px 0 #555, 0 -1px #555;
}
.expand-summary-btn {
  background: none;
  border:#8F8F8F 1px solid;
  color: black;
  cursor: pointer;
  padding: 5px;
  display: inline-block;
  font-size: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}


.video-responsive {
  position: relative;
  padding-bottom: 56.25%; /* Aspect ratio of 16:9 */
  height: 0;
  overflow: hidden;
  max-width: 100%; /* Add max-width */
}

.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-container{
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
}

.nav-bar-wrapper{
  width: 100%;
  padding: 20px 15px;
}

.nav-bar-main{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c6c6c6;
  padding: 10px 15px;
  box-shadow: 0 3px 5px -1px rgba(176, 7, 214, 0.05), 0 1px 3px -1px rgba(167, 11, 224, 0.03);
}

.nav-bar-main .app-name{
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: 700;
  color: #000;
}

.nav-bar-main .app-name img{
  margin-right: 14px;
  width: 50px;
}

.logged-container{
 padding: 100px 0;
}

.app-name{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: 700;
}

.app-name img{
  margin-right: 14px;
    width: 50px;
}

.App .main-details{
  padding-top: 20px;
  padding-bottom: 40px;
}

.App .main-details h1{
  font-size: 40px;
    font-weight: 700;
    margin: 0px 0 10px;
    background: -webkit-linear-gradient(#aa35c3, #ab35c4, #ce428a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 65px;
}

.App .main-details p{
  font-size: 16px;
    margin: 0;
    color: #777;
    max-width: 525px;
    margin: auto;
    padding: 0px 2.1px;

}

.linear-lines{
  display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
}

.linear-lines div{
  width: 30px;
  height: 3px;
  background-color: #606060;
}

.linear-lines .middle-linear{
  background-image: linear-gradient(to right,#aa35c3, #ab35c4, #ce428a);
  margin: 0 5px;
}

.App .content {
  margin: auto;
  position: relative;
  width: 550px;
  display: flex;
  justify-content: center;
}

.App .content .link{
  position: absolute;
  left: 9%;
    top: 12px;
    width: 20px;
}

.youtube-input {
  font-size: 18px;
  padding: 7px 10px 7px 50px;
  max-width: 500px;
  width: 100%; /* Adjust as needed */
  border: .01px solid #e3e1e1; /* Thin pencil color line */
  background-color: #f7f7f7;
  border-radius: 10px;
  margin-right: 10px;
  box-shadow: 2px 2px 4px rgba(85, 85, 85, 0.15); /* Reduced 3D effect with lighter shadow */
}


.summarize-button {
  font-size: 1em;
  padding: 10px 20px;
  background-color: #000; /* Red color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transform: translateZ(-1px);
  animation: rainbow-animation 15s linear infinite;
  transition: all .3s linear;
}

.summarize-button:hover {
  background-color: rgba(196, 2, 131, 0.913); /* Darker shade of red for hover effect */
  transform: translateZ(-1px);
  animation: rainbow-animation 15s linear infinite;
  transition: all .3s linear;
}

.App .right-pattern{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 280px;
}

.App .left-pattern{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 280px;
}

.main-footer .upper-footer{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 60px 0;
}

.main-footer .upper-footer h2{
  font-size: 26px;
  line-height: 40px;
  margin: 0 0 10px;
}

.main-footer .upper-footer li{
  font-size: 16px;
  line-height: 28px;
  margin: 0;
}

.main-footer{
  background-color: #ECECEC;
  position: relative;
}


.main-footer {
  position: relative;
  z-index: 300; /* Higher than the Progressbar */
}



.main-footer .lower-footer{
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-right: none;
  border-bottom: none;
  border-left: none;
  border-image: linear-gradient(to right, #fff, #000, #fff) 1;

}

.main-footer .lower-footer p{
  font-size: 12px;
  margin: 0;
  padding: 20px 0;
}

.subscription .sub-input {
  position: relative;
}

.subscription .sub-input img{
  position: absolute;
  left: 20px;
  top: 12px;
}

.subscription .sub-input input{
  width: 350px;
  height: 40px;
  border-radius: 50px;
  padding: 5px 50px;
  display: block;
  border: 1px solid #000;
  background-color: transparent;
}

.subscription .sub-input input::placeholder{
  color: #000;
}

.subscription .sub-button{
  width: 150px;
  height: 40px;
  border-radius: 50px;
  color: #fff;
  background: -webkit-linear-gradient(#aa35c3, #ab35c4, #ce428a);
  margin-top: 15px;
  font-size: 16px;
}

.fpattern{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 280px;
}

.lpattern{
  position: initial;
  left: 0;
  bottom: 0;
  width: 280px;
}

.common-heading{
  font-size: 32px;
  color: #000;
  font-weight: 700;
  margin-bottom: 20px;
  padding-top: 20px;
}
.common-text{
  font-size: 16px;
  color: #000;
  margin-bottom: 0;
}

.common-sub{
  font-size: 22px;
  margin: 15px 0 10px;
  font-weight: 600;
}

.common-sub-mid{
  font-size: 26px;
  color: #000;
  font-weight: 700;
  margin-bottom: 0;
}

.results-section .inner-results .summary{
  padding: 50px 0;
}
.summary-list {
  padding-left: 20px;
}

.summary-list li {
  margin-bottom: 10px;
}
.results-section .inner-results .details-container{
  border: 1px solid #8F8F8F;
  border-radius: 10px;
  padding: 20px 30px;
  margin: 18px auto;
  box-shadow: 0 1px 3px rgba(46, 46, 46, 0.15);
}

.results-section .inner-results .details-container p{
  text-align: left;
  margin-bottom: 20px;
}

.results-section .inner-results .details-container p strong{
  font-size: 20px;
  color: #303030;
}

.content-top-details{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.content-top-details .copy{
  margin-left: auto;
  height:15px;
}

.content-top-details {
  display: flex;
  align-items: center;
  margin: 15px 1px 15px;
  cursor: pointer;
}
.copyy{
  margin-left: auto;
  margin-bottom: 10px;
  height: 15px;
  padding-bottom: 0px;
  cursor: pointer;

}
.content-top-details .time{
  margin-right: 10px;
  height: 26px;
}

.content-top-details strong{
  margin-bottom: 2px;
}

.sent-rely{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  padding-bottom: 1.5% !important;
}

.sent-rely .sent{
  background-color: #e5e5e5ba !important;
  padding: 7px 7px 7px 10px !important;
  margin-bottom: 0px !important;
  width: 65%;
  text-align: left;
  border-radius: 10px;
  margin-right: 1.2%;
}

.sent-rely .rely{
  background-color: #f0f0f0;
  padding: 40px 38px;
  width: 30%;
  text-align: left;
  border-radius: 10px;
}

.sent-rely h2{
  font-size: 26px;
  margin-bottom: 10px;
  font-weight: 700;
}

.sent-rely p {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
}
.parad{
  text-align: left;
  margin-bottom: 10px;
  font-size: 16px;
}

.content-top-details strong{
  font-size: 16px;
  color: #303030;
  margin-left: 8px;
  font-weight: 600;
}

.loader-container{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 9;
  overflow-x: hidden;
}

.loader-container .loader-wrapper{
  max-width: 650px;
  width: 100%;
  margin: auto;
  margin-top: 100px;
}

.loader-container .loader-wrapper h2{
  font-size: 30px;
  margin-bottom: 25px;
  line-height: 30px;
  font-weight: 700;
}

.loader-container .loader-wrapper p{
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 27px;
}

.loader-end-txt{
  font-size: 20px;
  margin-top: 15px;
  line-height: 24px;
  color: black;
}
.loader-container .loader-wrapper .loaded-img{
  width: 33px;
  height: 32px;
}
.loader-container .loader-wrapper .loading-img{
  animation: rotation 2s infinite linear;
  width: 33px;
  height: 32px;
}
.seperate-loading{
width: 60px;
height: 60px;
animation: rotation 2s infinite linear;
margin: auto;
}
.another-div{
  padding-top: 100px;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loader-container .loader-txt{
  display: flex;
  justify-content: space-between;
  align-items: center;
}







.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-bottom: 10px;
  padding-top: 13px;
  padding-bottom: 8px;
}

.button-container .gradient-button .ggradient-button {
  flex-shrink: 0; /* Prevents buttons from shrinking */
  display: inline-block; /* Ensures buttons stay inline */
  margin-right: 3px;
}
.chat-container {
  width: 100%;
  margin-top: 10px;
}

.chatbot-container {
  position: fixed;
  top: 320px;
  bottom: 120px;
  height: 80vh;
  max-height: 700px;
  width: 4px;
  z-index: 1000;
  transition: all 0.3s ease;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}



.chatbot-body {
  position: relative;
  height: 500px;
  max-width: 100%;
}

.cs-message__content {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #000000 !important;
}

.cs-message--outgoing .cs-message__content {
  background-color: #007AFF !important;
  color: #ffffff !important;
  border-radius: 18px 18px 3px 18px;
}

.cs-message--incoming .cs-message__content {
  background-color: #E5E5EA !important;
  border-radius: 18px 18px 18px 3px;
}

.cs-message-list__scroll-wrapper {
  background-color: #ffffff !important;
  padding: 0 .8em 0 .8em !important;
}

.cs-message-input__content-editor {
  display: flex;
  color: #000000 !important;
}

.cs-message-input__content-editor-wrapper {
  box-sizing: border-box;
  position: relative;
  background-color: #ffffff !important;
  margin: 0;
  padding: .6em .9em;
  border-radius: 20px;
  flex-grow: 1;
}

.cs-message-input__content-editor {
  box-sizing: border-box;
  flex-grow: 1;
  background-color: #ffffff !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  outline: 0;
  border: none;
  overflow: visible;
  color: #000000 !important;
  overflow-wrap: anywhere;
  word-break: break-word;
}

.cs-message-input__content-editor[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: #8E8E93 !important;
  opacity: 0.7;
}

.cs-main-container {
  
  border: none !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px;
  background-color: #ffffff !important;
  margin-right: 7%;
  flex-direction: column-reverse;
  border-radius: 3% !important;
}

.cs-message__content {
  text-align: left;
  font-variant: normal;
}

cs-chat-container .cs-message-input {
  border-top: solid 1px #d1dbe3;
  border-right: solid .1px #323a411f;
  border-bottom: solid .01px #36393a09;
  border-left: solid .1px #d1dbe316;
  margin-top: auto;
  padding: .3em 0 .3em 0em;
  color: rgba(0, 0, 0, .87);
  background-color: #fff;
}
.cs-message-input {
  background-color: #ffffff !important;
  border-top: 1px solid #E5E5EA !important;
}

.cs-button {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.cs-button:hover {
  background-color: #000000 !important;
}

.cs-typing-indicator {
  background-color: #E5E5EA !important;
  color: #000000 !important;
}

/* New styles for the title */
.chatbot-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #6f6f6f;
  background: linear-gradient(135deg, #e0e0e0, #f5f5f5, #dcdcdc, #e8e8e8, #ebebeb, #eeeeee);
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.chatbot-title img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  padding-left: 0px;
  padding-left: 0px;
}

/* ... rest of your styles ... */

.cs-message-list__scroll-wrapper {
  background-color: #ffffff !important;
  padding: 0 .8em 0 .8em !important;
}
.cs-message-list__scroll-wrapper {
  background-color: #ffffff !important;
  padding: 0 .1em 0 .1em !important;
  height: 160% !important;  /* Added this line */
  width: 100% !important;   /* Added this line */
}
.cs-main-container,
.cs-chat-container,
.cs-message-list {
  height: 98.2% !important;
  width: 100% !important;
}


.terms-container {
    padding: 30px;
    max-width: 900px;
    margin: auto;
    font-family: Arial, sans-serif;
}

h1 {
    font-size: 30px !important;
    margin-bottom: 0px !important;
}

h2 {
    font-size: 24px!important;
    margin-top: 20px!important;
    margin-bottom: 10px!important;
}

h3 {
    font-size: 20px!important;
    margin-top: 15px!important;
    margin-bottom: 5px!important;
}

p {
    font-size: 14px!important;
    line-height: 1.6!important;
    margin-bottom: 1px!important;
}

strong {
    font-weight: bold!important;
}


.privacy-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

h2, h3, h4 {
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #333;
}

p, ul, li {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

ul li {
  margin-bottom: 10px;
}


h1, h2, h3, h4, h5, h6 {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 1px;
}

.subscription .sub-input input {
  width: 350px;
  height: 40px;
  border-radius: 30px;
  padding: 5px 15px;}


  .footer-links{
    font-size: 14px;
    color: rgb(14, 11, 24)
  }




  ul li {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s;
}

ul li:hover {
    background-color: #f0f0f0;
}

ul li svg {
    margin-right: 10px;
    font-size: 18px; /* Adjust the size of the icons */
}





.sentss {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 1.5%;
}

.sentss > div {
  width: 48%;
  height: 20%;
  background: linear-gradient(145deg, #f0f0f0, #e6e6e6);
  border-radius: 15px;
  padding: 5px 0px;
  box-shadow: 4px 4px 6px #d1d1d1, -3px -3px 6px #ffffff;
  transition: all 0.3s ease;
}



.sentss > div:first-child {
  margin-right: 1.2%;
}

.sentss p {
  margin: 0;
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.sentss b {
  font-weight: 600;
  display: inline-block;
  margin-right: 5px;
}

/* Sentiment-specific styles */
.sentss > div:first-child b {
  color: black;
}

/* Reliability-specific styles */
.sentss > div:last-child b {
  color: black;
}

.sentss > div:hover {
  box-shadow: 12px 12px 20px #d1d1d1, -12px -12px 20px #ffffff;
}




.sentss > div {
  width: fit-content;
  padding: 10px !important;
}


.results-section {
  margin: 50px auto;
  max-width: 800px;
}

.sents {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  padding-bottom: .6%;
}

.sents > div {
  width: 27%;
  height: 9%;
  background: linear-gradient(145deg, #f7f7f7, #ededed); /* More off-white */
  border-radius: 15px;
  padding: 5px 0px;
  box-shadow: 3.2px 3.2px 4.8px #d1d1d1, -2.4px -2.4px 4.8px #ffffff, 0px -2.4px 4.8px #d1d1d1; /* 20% less shadow and added to the top */
  transition: all 0.3s ease;
}



.sents > div:first-child {
  margin-right: 1.2%;
}

.sents p {
  margin: 0;
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.sents b {
  font-weight: 600;
  display: inline-block;
  margin-right: 5px;
}

/* Sentiment-specific styles */
.sents > div:first-child b {
  color: black;
}

/* Reliability-specific styles */
.sents > div:last-child b {
  color: black;
}

.sents > div:hover {
  box-shadow: 12px 12px 20px #d1d1d1, -12px -12px 20px #ffffff;
}






/* Add these new styles */
.app-container {
      display: flex;
      justify-content: center !important;
      max-width: 100%;
      margin: 0 0%;
      gap: 0px;
  }

.left-column {
  width: 30%; /* Adjust as needed */
}

.chat-container {
  height: 100%;
  overflow-y: auto;
  padding-right: 20px;
}

/* You might need to adjust the center and right column widths as well */
.center-column {
  width: 50%; /* Adjust as needed */
  padding-top: 40px;
 
}

.left-column, .center-column, .right-column {
  margin: 0 10px; /* Adds horizontal space around each column */
}

.right-column {
  width:25%; /* Adjust as needed */
  padding: 10px;
}

.app-container {
  display: flex;
  justify-content: center;
  max-width: 100%;
  margin: 0 0%;
  gap: 0px; /* Adds space between columns */
}

.left-column, .center-column, .right-column {
  margin: 0 10px; /* Adds horizontal space around each column */
}



/* Modify existing styles */
.App {
  text-align: center;
  padding: 8px 0 270px;
  position: relative;
  background: linear-gradient(
    to top,
    #f5f5f5 0%,  /* 10% more white */
    #f6f6f6 30%, /* 10% more white */
    #fbfbfb 60%, /* 10% more white */
    #ffffff 100% /* 10% more white */
  );
}

.custom-container {
  max-width: 100%; /* Change from 850px to 100% */
  width: 100%;
  margin: 0 auto;
}

/* Adjust other container widths */
.App .content {
  width: 100%; /* Change from fixed width to 100% */
  max-width: 550px; /* Add max-width to maintain current size */
}

.youtube-input {
  width: 100%; /* Change to 100% */
  max-width: 500px; /* Back to original wider size for desktop */
}


.main-footer .upper-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 15px 70px 15px !important;
}


.results-section .inner-results .details-container {

  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 -5px 10px -2px rgba(0, 0, 0, 0.05), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  margin: 1.125rem auto;
  position: relative;
  border: none;
}
.search-icon-button {
 

  background: #f00 !important;
 
}

.chat-container {
  position: static; /* Change to static on smaller screens */
  height: auto; /* Allow height to adjust to content */
  max-height: 500px; /* Set a max-height for smaller screens */
}


@media screen and (max-width: 1400px) {
  .linear-lines {
    margin-top: 3px;
  }
  .nav-bar-main .app-name {
    font-size: 24px;
  }
  .app-name {
    font-size: 24px;
  }
  .nav-bar-main .app-name img,
  .app-name img {
    width: 40px;
  }
  .App {
    padding: 10px 0 100px;
  }
  .App .main-details {
    padding-top: 15px;
  }
  .App .main-details h1 {
    font-size: 40px;
    font-weight: 700;
    margin: 0px 0 5px;
  }
  .linear-lines {
    margin-top: 30px;
  }
  .App .left-pattern,
  .App .right-pattern {
    width: 250px;
  }
  .youtube-input {
    max-width: 648px;
  }
  .main-footer .upper-footer {

    padding: 30px 0;
  }
  .main-footer .upper-footer h2 {
    font-size: 24px;
    line-height: 34px;
    margin: 0 0 10px;
  }
  .subscription .sub-input input {
    height: 45px;
  }
  .subscription .sub-input img {
    top: 16px;
  }
  .subscription .sub-button {
    width: 130px;
    height: 40px;
    border-radius: 50px;
    color: #fff;
    background: -webkit-linear-gradient(#aa35c3, #ab35c4, #ce428a);
    margin-top: 15px;
    font-size: 14px;
  }
  .fpattern {
    width: 200px;
  }
  .logged-container {
    padding: 30px 0;
  }
  .header-btn {
    font-size: 14px;
  }
  .App .content .link {
    left: 30px;
    top: 12px;
  }
  .App .content {
    width: 550px;
  }
  .gradient-button {
    padding: 10px 20px;
    font-size: 14px;
  }
  .sent-rely h2 {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 700;
  }
  .loader-container .loader-wrapper {
    margin-top: 60px;
  }
}

@media screen and (max-width: 1200px) {
  .nav-bar-wrapper {
    padding: 3px 5px;
  }
}

@media screen and (max-width: 991px) {
  .custom-container {
    max-width: 650px;
  }
  .app-container {
    flex-direction: column;
  }
  .left-column, .right-column, .center-column {
    width: 100%;
    padding: 10px 0;
  }
  .youtube-input {
    font-size: 14px;
    max-width: 405px;
    padding: 10px 10px 10px 50px;
  }
  .App .content .link {
    left: 30px;
    top: 12px;
    width: 18px;
  }
  .App .right-pattern,
  .App .left-pattern {
    width: 170px;
  }
  .upper-footer {
    flex-wrap: wrap;
  }
  .upper-footer .services-footer {
    margin-left: 50px;
  }
  .sent-rely h2 {
    font-size: 18px;
  }
  .sent-rely .sent {
    padding: 5px 5px;
    width: 65%;
  }
  .sent-rely .rely {
    padding: 30px 20px;
    width: 30%;
  }
  .common-heading {
    font-size: 28px;
  }
  .common-txt {
    font-size: 14px;
  }
  .common-sub,
  .common-sub-mid {
    font-size: 22px;
  }
  .results-section .inner-results .details-container p strong {
    font-size: 18px;
  }
  .loader-container .loader-wrapper {
    margin-top: 30px;
  }
}

.main-footer .lower-footer p {

  padding: 20px 0 10px !important;
}

@media screen and (max-width: 768px) {
  .header-btn, .gradient-button {
      margin-top: 20px;
  }
}
@media screen and (max-width: 640px) {
  .nav-bar-wrapper {
      padding: 0px 1px !important;
  }
}
@media screen and (max-width: 768px) {
  .nav-bar-main {
      padding: 10px !important;
  }
}
.nav-bar-wrapper {
  padding: 3px 15px 50px  !important;
  width: 100%;
}
@media screen and (max-width: 768px) {
  
  .App .content .link {
    left: 70px;
  }
  .App .main-details h1 {
    font-size: 30px;
  }
  .App .main-details {
    padding-top: 25px;
    padding-bottom: 20px !important
  }
  .app-name {
    font-size: 20px;
  }
  .app-name img {
    width: 35px;
  }
  .main-footer .upper-footer h2 {
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 10px;
  }
  .main-footer .upper-footer li {
    font-size: 14px;
    line-height: 22px;
    margin: 0;
  }
  .youtube-input {
    font-size: 14px;
    max-width: 280px; /* Smaller width for mobile */
    padding: 10px 10px 10px 50px;
  }
  .App .content .link {
    left: 70px;
  }
  .App .content {
    width: 80%;
  }
  .sent-rely .sent {
    padding: 20px 10px;
    width: 65%;
  }
  .sent-rely .rely {
    padding: 20px 10px;
    width: 30%;
  }
  .loader-wrapper {
    padding: 0 20px;
  }
  .app-container {
    margin-left: 0;
    margin-right: 0;
    padding: 0 10px;
  }
  .left-column, .center-column, .right-column {
    margin: 0 0px;
    padding: 0px 0;
}
.chat-container {
  width: 100%; /* Makes sure the chat container takes up the full width */
  margin-top: 10px; /* Adds space between buttons and the chat container */
}

.chatbot-container {
  width: 100%; /* Ensures the chatbot takes up the full width of the parent */
  position: relative;
}
  .chatbot-body {
    height: 100% !important;
  }
  .cs-main-container {
    border-radius: 10px 10px 0 0 !important;
  }
  .main-footer {
    position: static;
  }
}

@media screen and (max-width: 640px) {
  .youtube-input {
    max-width: 630px;
    height: 40px;
    margin-top: 20px;
}

  .App .content .link {
    left: 9%;
    margin-top: 0px;
}
  .App .content .gradient-button {
    margin-top: 0;
  }
  .gradient-button,
  .ggradient-button {
    padding: 10px 20px;
    margin-right: 3px;
    font-size: 14px;
    display: block;
    margin-top: 20px;
  }
  .App .left-pattern,
  .App .right-pattern {
    width: 110px;
  }
  .fpattern {
    width: 100px;
  }
  .subscription .sub-input input {
    width: 250px;
    font-size: 14px;
  }
  .header-btn {
    padding: 8px;
    font-size: 14px;
  }
  .nav-bar-wrapper .gradient-button .ggradient-button {
    display: inline-block;
    margin-top: 0;
  }
  .nav-bar-wrapper {
    padding: 3px 5px;
  }
  .sent-rely .sent {
    width: 50%;
    margin-bottom: 0px;
    text-align: center;
  }
  .sent-rely .rely {
    width: 100%;
    text-align: center;
  }
  .sent-rely {
    flex-direction: row;
  }
  .App .content {
    width: 90%;
  }
  .sentss,
  .sents {
    flex-direction: column;
  }
  .sentss > div,
  .sents > div {
    width: 100%;
    margin-bottom: 15px;
  }
  .sents > div:first-child,
  .sentss > div:first-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 440px) {

  .chatbot-container {
    height: 100vh;
    max-height: 300%;
   
  }
  .gradient-button,
  .ggradient-button {
    padding: 10px;
    margin-right: 3px;
    font-size: 11px;
    font-weight: 500;
    height: fit-content;
  }
  .header-btn {
    padding: 8px;
    font-size: 12px;
  }
  .nav-bar-main .app-name {
    font-size: 18px;
  }
  .nav-bar-main .app-name img {
    width: 35px;
  }
  .shadow-xl {
    box-shadow: none !important;
    border: none;
  }
  .App .content {
    width: 100%;
  }
}





.time-saved-container {
  display: flex;
  align-items: center;
  background-color: #f0f4f8;
  border-radius: 20px;
  padding: 5px 10px;
  margin-right: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.time-saved-label {
  margin-left: 3px;
  font-size: 0.8em;
  color: #4a5568;
}

.time-saved-value {
  font-size: 12px;
  font-weight: bold;
  color: #2d3748;
}

@media (max-width: 768px) {
  .time-saved-container {
    padding: 3px 6px;
  }

  .time-saved-label {
    display: none;
  }

  .time-saved-value {
    font-size: 10px;
  }
}

@media (max-width: 440px) {
  .time-saved-container {
    padding: 3px 6px;
  }

  .time-saved-label {
    display: none;
  }

  .time-saved-value {
    font-size: 10px;
  }
}

/* Add these new styles */
.static-chatbot {
  width: 100%;
  height: 600px;
  margin-top: 0px;
  border-radius: 10px;
  overflow: hidden;
}

.content-toggle-section {
  margin-top: 20px;
  width: 100%;
}

/* Update the existing chatbot container styles */
.chatbot-container {
  position: static !important;
  width: 100% !important;
  height: 600px !important;
  margin-top: 20px !important;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

/* Update existing media queries */
@media screen and (max-width: 768px) {
  .static-chatbot {
    height: 500px;
  }
  
  .chatbot-container {
    height: 500px !important;
    position: static !important;
  }

}

@media screen and (max-width: 440px) {
  .static-chatbot {
    height: 400px;
  }
  
  .chatbot-container {
    height: 400px !important;
  }
}

/* Add these styles to App.css */
.segmented-controls {
  display: flex;
  background-color: #f1f1f1;
  border-radius: 30px;
  padding: 6px;
  margin: 20px 0;
  width: fit-content;
  margin: 15px auto;
  gap: 6px;
}

.segmented-controls button {
  border: none;
  padding: 10px 28px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  background: transparent;
  color: #666;
  transition: all 0.3s ease;
  min-width: 120px;
}

.segmented-controls button.active {
  background-color: white;
  color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.segmented-controls button:hover:not(.active) {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Add responsive adjustments */
@media screen and (max-width: 768px) {
  .segmented-controls button {
    padding: 8px 20px;
    font-size: 12px;
    min-width: 90px;
  }
}

@media screen and (max-width: 480px) {
  .header-btn, .gradient-button {
      padding: 6px 12px;
      font-size: 12px;
      margin-top: 5px;
  }
}

@media screen and (max-width: 480px) {
  .youtube-input {
      max-width: 75%;
  }
}




.summarize-section {
  position: static;
  background: #fbf8f852;
  padding-top: 20px;
  box-shadow: none;
  margin-bottom: 0px;
}

.summarize-section .content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  max-width: 765px;
  margin: 0 auto;
  flex-wrap: nowrap;
}

@media screen and (max-width: 768px) {
  .summarize-section {
    padding: 15px 0;
  }
  
  .summarize-section .content {
    flex-direction: row;
    gap: 8px;
    padding: 0 15px;
    max-width: 100%;
  }

  .youtube-input {
    max-width: 70%;
    height: 40px;
    font-size: 13px;
    margin: 0;
  }

  .summarize-section .content button {
    padding: 8px 15px;
    font-size: 13px;
    white-space: nowrap;
    margin: 0;
  }
}

@media screen and (max-width: 480px) {
  .summarize-section .content {
    padding: 0 10px;
  }


  
  .summarize-section .content button {
    padding: 8px 12px;
    font-size: 12px;
  }
}

/* Add these styles for the sticky video container */
.sticky-video-container {
  position: sticky;
  top: 80px;
  z-index: 900;
  transition: all 1.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  max-width: 850px;
  background: white;
  padding: 0;
  border-radius: 10px;
  transform-origin: top right;
  will-change: transform;
}

.sticky-video-container.scrolled {
  position: fixed;
  width: 30.67%;
  max-width: 39.67%;
  right: 2%;
  top: 34%;
  transform: scale(0.67);
  transform-origin: bottom right;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Keep the title transition smooth as well */
.sticky-video-container .video-title {
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Add a placeholder to prevent content jump */
.sticky-video-container::after {
  content: '';
  display: block;
  height: 0;
  visibility: hidden;
}

/* Keep original space when video floats */
.sticky-video-container.scrolled::after {
  content: '';
  display: block;
  padding-bottom: .25%; /* 16:9 aspect ratio */
  visibility: visible;
}

/* Remove the padding adjustment from right column */
.right-column.has-sticky-video {
  padding-top: 0;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .sticky-video-container {
    position: sticky;
    top: 65px;
  }
  
  .sticky-video-container.scrolled {
    position: sticky;
    width: 100%;
    right: auto;
    transform: none;
    margin: 0 auto;
  }
  
  .right-column.has-sticky-video {
    padding-top: 10px;
  }
}

/* Add this new style for when chatbot is open */
.chatbot-open .sticky-video-container {
  position: relative !important;
  top: 0 !important;
  width: 100% !important;
  transform: none !important;
  right: auto !important;
  box-shadow: none !important;
}

.chatbot-open .sticky-video-container.scrolled {
  position: relative !important;
  transform: none !important;
}





.trending-videos-wrapper {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 35px 0 0; /* Reduced top padding */
  position: relative;
}

.trending-videos-title {
  font-size: 15px !important;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 2px;
  padding-left: 5px ;
  text-align: left;
  letter-spacing: -0.5px; /* Tighter letter spacing */
}

.trending-videos-scroll {
  display: flex;
  gap: 16px; /* Reduced gap between cards */
  padding: 10px 40px 20px;
  animation: scroll 60s linear infinite;
  width: max-content;
}

.trending-videos-scroll > * {
  flex-shrink: 0;
}

/* Modified animation keyframes */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Show scrollbar only on hover */
.trending-videos-wrapper:hover {
  overflow-x: auto;
}

/* Hide scrollbar by default */
.trending-videos-wrapper::-webkit-scrollbar {
  height: 8px;
  display: none;
}

.trending-videos-wrapper:hover::-webkit-scrollbar {
  display: block;
}

.trending-video-item {
  min-width: 280px;
  max-width: 280px;
  cursor: pointer;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}

.trending-video-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 20px -8px rgba(0, 0, 0, 0.15);
}

.trending-thumbnail {
  width: 100%;
  height: 157px;
  object-fit: cover;
}

.trending-video-info {
  padding: 12px 16px;
}

.trending-video-title {
  font-size: 14px;
  font-weight: 600;
  color: #0f0f0f;
  margin: 0 0 6px 0;
  line-height: 1.3;
  -webkit-line-clamp: 2;
}

.trending-channel-name {
  font-size: 13px !important;
  color: #606060;
  margin: 4px 0;
  font-weight: 500;
}

.trending-video-stats {
  font-size: 12px !important;
  color: #606060;
  margin: 4px 0;
}

.trending-videos-wrapper::-webkit-scrollbar {
  height: 8px;
}

.trending-videos-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.trending-videos-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.trending-videos-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 768px) {
  .trending-video-item {
    min-width: 196px;
    max-width: 196px;
  }

  .trending-thumbnail {
    height: 110px;
  }

  .trending-video-info {
    padding: 8px 12px;
  }

  .trending-video-title {
    font-size: 10px;
  }

  .trending-channel-name {
    font-size: 11px !important;
  }

  .trending-video-stats {
    font-size: 10px !important;
  }
}

/* Trending videos container */
.trending-videos {
  transform: scale(0.8);
  transform-origin: top center;
  margin: -10% 0;
}

/* If you need to adjust the individual cards */
.trending-video-card {
  width: 80%;
  margin: 0 auto;
}

/* Adjust the image size if needed */
.trending-video-card img {
  width: 80%;
  height: auto;
}

/* Adjust text sizes proportionally */
.trending-video-card h3 {
  font-size: 0.9em;
}

.trending-video-card p {
  font-size: 0.8em;
}

.error-popup {
  position: fixed;
  top: 450px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  padding: 13px 26px;
  border-radius: 40px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
  animation: fadeInOut 3s ease-in-out forwards;
  font-size: 13px;
  color: #333;
  border: 1px solid #eee;
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translate(-50%, 16px); }
  15% { opacity: 1; transform: translate(-50%, 0); }
  85% { opacity: 1; transform: translate(-50%, 0); }
  100% { opacity: 0; transform: translate(-50%, -16px); }
}

.loading-related {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  color: #666;
}

.loading-related .spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #8b3dff; /* Purple to match your theme */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1px;
}

.loading-related .text {
  font-size: 15px;
  color: #666;
  margin-top: 1px;
  font-weight: 500;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.video-container.sticky {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 350px;
  z-index: 999;
  transition: all 0.3s ease;
}

/* Mobile styles with maximum override */
@media screen and (max-width: 768px) {
  .video-container,
  .video-container.sticky,
  .video-container.sticky iframe,
  .video-container iframe {
    position: fixed !important;
    width: 90vw !important;
    height: 50.625vw !important;
    max-width: 90% !important;
    max-height: none !important;
    top: 0 !important;
    right: 0 !important;
    left: 5% !important;
    transform: none !important;
    transition: none !important;
    scale: 1 !important;
    /* Add shadow at the bottom */
    box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.2), 
                0 6px 12px -3px rgba(0, 0, 0, 0.15) !important;
    border-radius: 0 0 10px 10px !important; /* Optional: rounds the bottom corners */
  }
}

/* Add mobile-specific styles */
@media screen and (max-width: 768px) {
    .content-top-details .time {
        margin-right: 1px;
        margin-bottom: 1px;
        height: 23px;
    }
}

.login-btns {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.header-btn {
  background-color: transparent;
  color: #000;
  padding: 8px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
}

.gradient-button {
  background-color: #000;
  color: #fff;
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .nav-bar-main {
    padding: 10px;
  }
  
  .header-btn, .gradient-button {
    padding: 6px 15px;
    font-size: 13px;
  }
  
  .gradient-button {
    white-space: nowrap;
  }
}

@media screen and (max-width: 480px) {
  .header-btn, .gradient-button {
    padding: 6px 12px;
    font-size: 12px;
  }
}